import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import QRCode from "qrcode.react";

const QRModal = ({ invoice, toggleModal }) => (
  <Modal isOpen={true} toggle={toggleModal}>
    <ModalHeader toggle={toggleModal}>Scan to Pay</ModalHeader>
    <ModalBody className="text-center m-lg">
      <QRCode value={invoice.request} />
    </ModalBody>
  </Modal>
);

QRCode.propTypes = {
  invoice: PropTypes.object,
  toggleModal: PropTypes.func
};

export default QRModal;
