import * as types from "./types";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import constants from "../../../shared/constants";

const querystring = require("querystring");

export function getUser(profile, socket) {
  return async function(dispatch) {
    try {
      const response = await axios.get("/api/user?username=" + profile.name);
      if (isEmpty(response.data)) return dispatch(addUser(profile, socket));
      console.log(response);
      // handle success
      console.log("response.data", response.data);
      if (isEmpty(response.data)) dispatch(addUser(profile, socket));
      dispatch(onSuccess(response.data, socket));
    } catch (error) {
      // handle error
      console.log("getUser error", error);
      // TODO: only create if 400
      dispatch(addUser(profile, socket));
    }
  };
}

export function addUser(profile, socket) {
  if (isEmpty(profile) || !profile.name) return;
  return async function(dispatch) {
    try {
      const response = await axios.post(
        "/api/user",
        querystring.stringify({
          user_id: uuidv4(),
          username: profile.name,
          email: profile.email
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      // handle success
      console.log("response.data", response.data);
      dispatch(onSuccess(response.data, socket));
    } catch (error) {
      // handle error
      console.log("addUser error", error);
    }
  };
}

export function addGuestUser(username, socket) {
  // const userId = `guest-${Math.random().toString(10)}`;
  const userId = uuidv4();
  const user = {
    user_id: userId,
    username
  };
  socket.emit(constants.MSG_TYPES.CREATE_USER, { isAuthenticated: false, userId });
  return {
    type: types.GET_USER,
    payload: user
  };
}

const onSuccess = (data, socket) => {
  console.log("onSuccess", data[0]);
  socket.emit(constants.MSG_TYPES.CREATE_USER, { isAuthenticated: true, userId: data[0].user_id });
  return {
    type: types.GET_USER,
    payload: data[0]
  };
};
