import * as types from "./types";

export const loginRequest = () => ({
  type: types.LOGIN_REQUEST
});

export const loginSuccess = profile => ({
  type: types.LOGIN_SUCCESS,
  payload: profile
});

// export const guestLoginSuccess = profile => ({
//   type: types.GUEST_LOGIN_SUCCESS,
//   payload: profile,
// })

export const loginError = error => ({
  type: types.LOGIN_ERROR,
  error
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

// sub: "auth0|5e64158bc6dbc90d3de21d77"
// nickname: "linabell"
// name: "linabell@mail.com"
// picture: "https://s.gravatar.com/avatar/7ef4fd378e1f9583135bbdd9a328020f?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fli.png"
// updated_at: "2020-03-08T02:05:14.205Z"
// email: "linabell@mail.com"
// email_verified: false
