import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "reactstrap";
import Loading from "components/Loading";
import { getLevelAndProgression } from "../utils/game-utils";

// TODO: add option to purchase multiple games if logged in
const GameOver = ({ balance, cashOut, loading, playAgain, satoshisEarned, username }) => {
  const canCashOut = balance > 0;
  const { level, levelProgression } = getLevelAndProgression(satoshisEarned);
  return (
    <div id="game-over-menu" className="menu">
      <h3>Good game, {username}!</h3>
      <p>Total Sats: {Math.floor(balance)}</p>
      <p>Sats Earned this Game: {Math.floor(satoshisEarned)}</p>
      <p>Level: {Math.floor(level)}</p>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col xs={canCashOut ? 6 : 12}>
              <Button color="danger" className="full-width" onClick={playAgain}>
                Play Again
              </Button>
            </Col>
            {canCashOut && (
              <Col xs="6">
                <Button color="secondary" className="full-width" onClick={cashOut}>
                  Cash Out
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};

GameOver.propTypes = {
  balance: PropTypes.number,
  cashOut: PropTypes.func,
  level: PropTypes.number,
  loading: PropTypes.bool,
  playAgain: PropTypes.func,
  satoshisEarned: PropTypes.number,
  username: PropTypes.string
};

export default GameOver;
