import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import * as authService from "utils/auth";
import SocketContext from "utils/socket";
import getErrorMsg from "utils/getErrorMsg";
import { addInvoice, getTransactions, saveTransaction, createPlayer } from "utils/networking";
import { authActions } from "redux/modules/auth";
import { userActions } from "redux/modules/user";
import constants from "shared/constants";
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Row,
} from "reactstrap";
import Loading from "components/Loading";
import QRModal from "components/QRModal";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
      errorMessage: "",
      directChannelCopied: false,
      invoice: null,
      isDropdownOpen: false,
      isModalOpen: false,
      loading: false,
      numPlayers: 0,
      profile: {},
      publicKey: "",
      redirectToGame: false,
      username: "",
    };
  }

  componentDidMount() {
    this.setSocketMonitors();
    this.getUserDetails();
    !this.props.auth.isAuthenticated && this.handleLogoutClick();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth || prevProps.user !== this.props.user) {
      this.getUserDetails();
    }
  }

  setSocketMonitors = async () => {
    const socket = this.props.socket;
    socket.on(constants.MSG_TYPES.NUM_PLAYERS, this.updateNumPlayers);
    socket.on(constants.MSG_TYPES.ADDED_INVOICE, this.onInvoiceAdded);
    socket.on(constants.MSG_TYPES.INVOICE_PAID, this.onInvoicePaid);
    socket.on(constants.MSG_TYPES.UPDATE_BALANCE, this.onUpdateBalance);
    socket.on(constants.MSG_TYPES.ON_ERROR, this.onError);
    socket.on(constants.MSG_TYPES.ON_SUCCESS, this.onSuccess);
  };

  handleLoginClick = () => {
    authService.login();
    this.props.loginRequest();
  };

  handleLogoutClick = () => {
    this.props.logoutSuccess();
    authService.logout(); // careful, this is a static method
    this.props.history.push({ pathname: "/" });
  };

  getUserDetails = () => {
    const { auth, getUser, socket, user } = this.props;
    if (!isEmpty(auth.profile) && isEmpty(user)) {
      getUser(auth.profile, socket);
    }
    if (!isEmpty(user)) getTransactions(user.user_id);
  };

  updateNumPlayers = (numPlayers) => {
    this.setState({ numPlayers });
  };

  changeUsername = (e) => {
    this.setState({ username: e.target.value });
  };

  changePublicKey = (e) => {
    this.setState({ publicKey: e.target.value });
  };

  toggleDropdown = () => {
    const isDropdownOpen = this.state.isDropdownOpen ? false : true;
    this.setState({ isDropdownOpen });
  };

  toggleModal = () => {
    const isModalOpen = this.state.isModalOpen ? false : true;
    this.setState({ isModalOpen, loading: false });
  };

  createInvoice = async (value) => {
    this.setState({ loading: true, invoiceValue: value });
    return addInvoice(value);
  };

  createGuestInvoice = async (value) => {
    const { username } = this.state;
    if (username === "") {
      this.setState({ errorMessage: "Please enter a Display Name" });
      return;
    }
    // const guest = {
    //   name: username,
    //   nickname: username,
    //   publicKey: publicKey
    // };
    // this.props.guestLoginSuccess(guest);
    this.props.addGuestUser(username, this.props.socket);
    this.createInvoice(value);
  };

  onInvoiceAdded = (invoice) => {
    console.log("onInvoiceAdded", invoice);
    this.setState({ invoice, isModalOpen: true });
  };

  onInvoicePaid = async ({ amount, internalId, invoiceId }) => {
    const { socket, user } = this.props;
    const balance = this.state.balance + amount;
    if (internalId !== socket.id) return;
    // const redirectToGame = amount === constants.PLAYER_COST ? true : false;
    const onSuccess = amount === constants.PLAYER_COST ? "createPlayer" : null;
    await saveTransaction({ invoiceId, onSuccess, userId: user.user_id });
    this.setState({ balance, invoice: null });
    // redirectToGame && this.handleCreatePlayer();
  };

  handleCreatePlayer = async () => {
    await createPlayer({ onSuccess: "redirectToGame", userId: this.props.user.user_id });
  };

  redirectToGame() {
    this.setState({ redirectToGame: true });
  }

  onUpdateBalance = (balance) => {
    this.setState({ balance });
  };

  onError = (error) => {
    const errorMessage = getErrorMsg(error);
    this.setState({ errorMessage, loading: false });
  };

  onSuccess = (callback) => {
    this.setState({ errorMessage: "" });
    switch (callback) {
      case "createPlayer":
        return this.handleCreatePlayer();
      case "redirectToGame":
        return this.redirectToGame();
      default:
        return null;
    }
  };

  renderContent = () => {
    const { balance, isDropdownOpen, publicKey, username } = this.state;
    const auth = this.props.auth;
    const canPlay = balance >= constants.PLAYER_COST;

    if (auth.isAuthenticated) {
      return (
        <>
          <h3>Welcome {auth.profile.nickname}</h3>
          <p>Your current balance is {balance}</p>
          <Row className="mb-sm">
            <Col xs="6">
              {canPlay ? (
                <Button color="danger" className="full-width" onClick={this.handleCreatePlayer}>
                  Play!
                </Button>
              ) : (
                <Button
                  color="danger"
                  className="full-width"
                  onClick={() => this.createInvoice(constants.PLAYER_COST)}
                >
                  Play!
                </Button>
              )}
            </Col>
            <Col xs="6">
              <Button color="secondary" className="full-width" onClick={this.handleLogoutClick}>
                Logout
              </Button>
            </Col>
          </Row>
          <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle caret className="full-width">
              Purchase Multiple Games
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.createInvoice(constants.PLAYER_COST * 5)}>
                5 Games
              </DropdownItem>
              <DropdownItem onClick={() => this.createInvoice(constants.PLAYER_COST * 10)}>
                10 Games
              </DropdownItem>
              <DropdownItem onClick={() => this.createInvoice(constants.PLAYER_COST * 20)}>
                20 Games
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      );
    }

    return (
      <Container>
        <Row className="mb-sm">
          <Col xs="12">
            <Button color="secondary" className="full-width" onClick={this.handleLoginClick}>
              Login
            </Button>
          </Col>
        </Row>
        <Row className="mt-sm">
          <Col xs="12">
            <p>or play as a guest</p>
          </Col>
        </Row>
        <Row className="mb-sm">
          <Col xs="6">
            <Input
              type="text"
              id="username-input"
              value={username}
              placeholder="Display Name"
              onChange={this.changeUsername}
            />
          </Col>
          <Col xs="6">
            <Input
              type="text"
              id="publicKey-input"
              value={publicKey}
              placeholder="Public Key"
              onChange={this.changePublicKey}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Button
              color="danger"
              className="full-width"
              onClick={() => this.createGuestInvoice(constants.PLAYER_COST)}
            >
              Play!
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  render = () => {
    const { errorMessage, redirectToGame, loading, isModalOpen, invoice, numPlayers } = this.state;
    const auth = this.props.auth;

    console.log(redirectToGame);
    if (redirectToGame) return <Redirect push to="/game" />;

    return (
      <>
        <div id="play-menu">
          {/*<h1>Satoshi's War</h1>*/}
          <img src="main_text.svg" width="375px" alt="Satoshi's War" style={{ marginBottom: 15 }} />
          <p>There are currently {numPlayers} players</p>
          <div className="menu">
            {loading ? <Loading /> : this.renderContent()}
            {auth.error && <p>{JSON.stringify(auth.error)}</p>}
          </div>
        </div>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        {invoice && isModalOpen && <QRModal invoice={invoice} toggleModal={this.toggleModal} />}
      </>
    );
  };
}

Welcome.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    error: PropTypes.object,
  }).isRequired,
  // guestLoginSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loginRequest: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  logoutSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user.info,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loginRequest: () => dispatch(authActions.loginRequest()),
  loginSuccess: (profile) => dispatch(authActions.loginSuccess(profile)),
  // guestLoginSuccess: profile => dispatch(authActions.guestLoginSuccess(profile)),
  logoutSuccess: () => dispatch(authActions.logoutSuccess()),
  getUser: (profile, socket) => dispatch(userActions.getUser(profile, socket)),
  addGuestUser: (username, socket) => dispatch(userActions.addGuestUser(username, socket)),
});

const WelcomeWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Welcome {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomeWithSocket));
