import React from "react";
import * as Sentry from "@sentry/browser";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from "./redux/configureStore";
import registerServiceWorker from "./registerServiceWorker";
import App from "./app/App";
import "bootstrap/dist/css/bootstrap.min.css";

const store = configureStore();
// Error logging
Sentry.init({ dsn: "https://b4bb988df83548128748db7b4dd770a1@o377419.ingest.sentry.io/5199525" });

render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>,
	document.getElementById("root")
);
// registerServiceWorker();
