import * as types from "./types";

const userReducer = (state = { info: {} }, action) => {
  switch (action.type) {
    case types.GET_USER:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default userReducer;
