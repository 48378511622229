module.exports = Object.freeze({
  // Bullets
  BULLET_EXIST_TIME_SECONDS: 4, // seconds until bullets are destroyed
  SPREAD_FACTOR: 4, // Factor for determining bullet spread.  Multiplied by spreadReducer to determine actual spread.  Higher numbers reduce spread.

  // Players
  PLAYER_COST: 500, // satoshis
  PLAYER_RADIUS_FACTOR: 17.5, // Divided by PLAYER_MAX_HP and player honeypot to calculate player size
  PLAYER_PERCENT_HP: 0.7, // Percent of cost allocated to hitpoints
  PLAYER_MAX_HP: 500 * 0.7,
  HONEYPOT_PERCENT: 0.2, // Percent of damage converted into honeypot instead of earnings.  Honeypot paid out as additional earnings to attackers.

  PLAYER_SPEED_INITIAL: 230,
  PLAYER_SPEED_MODIFIER: 7, // additional movement speed per skill point allocated
  PLAYER_SPEED_LEVEL_UP_REDUCER: 2, // Amount slowed down every time level up.  Max level is 40.

  BULLET_SPEED_INITIAL: 300,
  BULLET_SPEED_MODIFIER: 40, // additional bullet speed per skill point allocated

  BULLET_DAMAGE_INITIAL: 5, // satoshis earned per hit
  BULLET_DAMAGE_MODIFIER: 1, // additional damage per skill point allocated

  PLAYER_FIRE_RATE_INITIAL: 0.6, // Lower is faster shooting speed
  PLAYER_FIRE_RATE_MODIFIER: 0.02, // Amount of reduction per skill point allocated

  PLAYER_MAX_SIZE: 500, // Applies to players and bots

  // Orbs
  ORB_ALLOCATION: 0.05, // Percent of cost allocated to creating orbs
  SMALL_ORB_HP: 10,
  SMALL_ORB_SPEED_INITIAL: 0,

  // Bot
  BOT_ALLOCATION: 0.1,

  BOT_SPEED_INITIAL: 85,
  BOT_SPEED_MODIFIER: 6, // additional movement speed per skill point allocated
  BOT_SPEED_LEVEL_UP_REDUCER: 2, // Amount slowed down every time level up.  Max level is 40, so can bring bot to speed of 20 with no upgrades.

  BOT_BULLET_SPEED_INITIAL: 250,
  BOT_BULLET_SPEED_MODIFIER: 30, // additional bullet speed per skill point allocated

  BOT_BULLET_DAMAGE_INITIAL: 4, // satoshis earned per hit
  BOT_BULLET_DAMAGE_MODIFIER: 1, // additional damage per skill point allocated

  BOT_FIRE_RATE_INITIAL: 0.8, // Lower is faster shooting speed
  BOT_FIRE_RATE_MODIFIER: 0.02, // Amount of reduction per skill point allocated

  // PLAYER_ARMOR_INITIAL: 0, // Damage Taken = Bullet_Damage * (10 / (10 + 2 * Armor stat))

  // PLAYER_SHIELD_PROTECTION_INITIAL: 10, // sats of protection
  // PLAYER_SHIELD_RECHARGE_INITIAL: 5, // seconds

  MAP_SIZE: 3000,

  MSG_TYPES: {
    // General
    ON_SUCCESS: "on_success",
    ON_ERROR: "on_error",
    CREATE_USER: "create_user",
    NUM_PLAYERS: "num_players",

    //Player
    JOIN_GAME: "join_game",
    GAME_UPDATE: "update",
    INPUT: "input",
    GAME_OVER: "dead",
    SHOOT: "shoot",
    STOPSHOOT: "shop_shoot",
    END_GAME: "end_game",

    //Lightning
    ADD_INVOICE: "add_invoice",
    ADD_WITHDRAWAL_REQUEST: "add_withdrawal_request",
    ADDED_INVOICE: "added_invoice",
    ADDED_WITHDRAWAL_REQUEST: "added_withdrawal_request",
    CREATE_PLAYER: "create_player",
    GET_TRANSACTIONS: "get_transactions",
    INVOICE_AUTH_CONFIRMED: "invoice_auth_confirmed",
    INVOICE_PAID: "invoice_paid",
    UPDATE_BALANCE: "update_balance",
    WITHDRAWAL_REQUEST_PAID: "withdrawal_request_paid",
  },

  TRANSACTION_TYPES: {
    PURCHASED: "purchased",
    EARNED: "earned",
    REDEEMED: "redeemed",
  },
});
