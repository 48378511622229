import React from "react";
import PropTypes from "prop-types";
import { getLevelAndProgression } from "../utils/game-utils";
import { Col, Row } from "reactstrap";

const ScoreAndLevel = ({ score, username }) => {
  const { level, levelProgression } = getLevelAndProgression(score);
  return (
    <div className="game-menu">
      <Row>
        <Col xs="12">
          <h5>{username}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div>Score: {Math.floor(score)}</div>
        </Col>
        <Col xs="12">
          <div>Level: {Math.floor(level)}</div>
        </Col>
        <Col xs="12">
          <div>{Math.floor(levelProgression * 100)}% to Next Level</div>
        </Col>
      </Row>
    </div>
  );
};

ScoreAndLevel.propTypes = {
  score: PropTypes.number,
  username: PropTypes.string
};

export default ScoreAndLevel;
