import React from "react";
import { debounce } from "throttle-debounce";
import { getAsset } from "utils/assets";
import { FormatLargeNumber } from "utils/tools";
import { getBaseUpdate, currentServerTime, distanceTo } from "utils/game-utils";
import { interpolateObject, interpolateObjectArray } from "utils/tools";

const constants = require("shared/constants");
const { PLAYER_RADIUS_FACTOR, PLAYER_MAX_HP, SMALL_ORB_HP, MAP_SIZE, PLAYER_MAX_SIZE } = constants;

class Canvas extends React.PureComponent {
  constructor(props) {
    super(props);
    // The "current" state will always be RENDER_DELAY ms behind server time, which makes gameplay smoother and lag less noticeable.
    this.renderDelay = 100;
    this.gameUpdates = [];
    this.gameStart = props.gameStart;
    this.firstServerTimestamp = props.firstServerTimestamp;
    this.backgroundImg = new Image();
    this.backgroundImg.src = "grid.svg";
    this.score = 0;
    // this.canvasDimensionResizer;
  }

  componentDidMount() {
    this.setCanvasDimensions();
    window.addEventListener("resize", debounce(40, this.setCanvasDimensions));
    this.props.socket.on(constants.MSG_TYPES.GAME_UPDATE, this.processGameUpdate);
    // this.canvasDimensionResizer = setInterval(this.setCanvasDimensions, 5000);
  }

  componentWillUnmount() {
    // clearInterval(this.canvasDimensionResizer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gameStart !== this.props.gameStart) this.gameStart = this.props.gameStart;
    if (prevProps.firstServerTimestamp !== this.props.firstServerTimestamp)
      this.firstServerTimestamp = this.props.firstServerTimestamp;
  }

  processGameUpdate = (update) => {
    if (!this.firstServerTimestamp) {
      this.firstServerTimestamp = update.t;
      this.gameStart = Date.now();
    }

    this.gameUpdates.push(update);

    // const { players, orbs, bots, bullets, leaderboard } = update;

    // // const me = players.find((el) => el.id === this.props.playerId); // Search for 'me' by player id, which was passed up from backend.
    // // console.log(me);
    // // Filter everything to only show items that are within user's screen view.
    // const distance = Math.max(window.innerHeight, window.innerWidth);

    // const bulletsToRender = bullets.filter((b) => distanceTo(b, me) <= distance);
    // const playersToRender = players.filter((b) => distanceTo(b, me) <= distance);
    // const botsToRender = bots.filter((b) => distanceTo(b, me) <= distance);
    // const orbsToRender = orbs.filter((b) => distanceTo(b, me) <= distance);

    // this.gameUpdates.push({
    //   me,
    //   players: playersToRender,
    //   bullets: bulletsToRender,
    //   orbs: orbsToRender,
    //   bots: botsToRender,
    // });

    // Keep only one game update before the current server time
    const serverTime = currentServerTime(
      this.firstServerTimestamp,
      this.gameStart,
      this.renderDelay
    );
    const base = getBaseUpdate(serverTime, this.gameUpdates);
    if (base > 0) {
      this.gameUpdates.splice(0, base);
    }

    this.getCurrentState(base, serverTime);
  };

  // Returns { me, players, bullets }
  getCurrentState = (base, serverTime) => {
    if (!this.firstServerTimestamp || !this.refs.canvas) {
      return {};
    }
    if (base >= 0 && base !== this.gameUpdates.length - 1) {
      const baseUpdate = this.gameUpdates[base];
      const next = this.gameUpdates[base + 1];
      if (!baseUpdate) return;
      const ratio = (serverTime - baseUpdate.t) / (next.t - baseUpdate.t);

      const gameState = {
        me: interpolateObject(baseUpdate.me, next.me, ratio),
        players: interpolateObjectArray(baseUpdate.players, next.players, ratio),
        orbs: interpolateObjectArray(baseUpdate.orbs, next.orbs, ratio),
        bots: interpolateObjectArray(baseUpdate.bots, next.bots, ratio),
        bullets: interpolateObjectArray(baseUpdate.bullets, next.bullets, ratio),
      };

      this.updateCanvas(gameState);
    }
  };

  updateCanvas = (gameState) => {
    const { players, bullets, orbs, bots } = gameState;

    const me = players.find((el) => el.id === this.props.playerId); // Search for 'me' by player id, which was passed up from backend.

    if (!me) return console.log("No me");

    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");

    // Draw background
    this.renderBackground(me.x, me.y);

    // Draw boundaries
    context.strokeStyle = "black";
    context.lineWidth = 5;
    context.strokeRect(canvas.width / 2 - me.x, canvas.height / 2 - me.y, MAP_SIZE, MAP_SIZE);

    // Draw all bullets
    bullets.forEach(this.renderBullet.bind(null, me));

    // Draw all orbs
    orbs.forEach(this.renderOrb.bind(null, me));

    // Draw all bots
    bots.forEach(this.renderBot.bind(null, me));

    // Draw all players
    this.renderPlayer(me, me);

    players.forEach(this.renderPlayer.bind(null, me));
    // })
  };

  setCanvasDimensions = () => {
    const canvas = this.refs.canvas;
    // On small screens (e.g. phones), we want to "zoom out" so players can still see at least
    // 800 in-game units of width.
    // Increase scale as level up to show more of the game screen.
    if (!canvas) return;
    const scaleRatio = Math.max(1, 800 / window.innerWidth);
    canvas.width = scaleRatio * window.innerWidth;
    canvas.height = scaleRatio * window.innerHeight;
  };

  renderBackground = (x, y) => {
    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");
    // const backgroundX = canvas.width / 2 - x - window.innerWidth / 2;
    // const backgroundY = canvas.height / 2 - y - window.innerHeight / 2;
    const backgroundX = canvas.width / 2 - x;
    const backgroundY = canvas.height / 2 - y;

    context.fillStyle = "black";
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.drawImage(
      this.backgroundImg,
      backgroundX,
      backgroundY,
      // MAP_SIZE + window.innerWidth,
      // MAP_SIZE + window.innerHeight
      MAP_SIZE,
      MAP_SIZE
    );
  };

  // Renders a ship at the given coordinates
  renderPlayer = (me, player) => {
    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");
    const { x, y, rotation, hp, max_hp, honeypot, username, score, color } = player;
    const canvasX = canvas.width / 2 + x - me.x;
    const canvasY = canvas.height / 2 + y - me.y;

    const playerRadius = Math.min(max_hp / 20 + 20, PLAYER_MAX_SIZE);

    // Draw ship
    context.save();
    context.translate(canvasX, canvasY);

    // Fix issue where ship seems to jump when outside normal parameter
    if (rotation && rotation > 0.01 && rotation < 6.27) {
      context.rotate(rotation);
    } else {
      context.rotate(0);
    }

    context.drawImage(
      getAsset("ship.svg"),
      -playerRadius,
      -playerRadius,
      playerRadius * 2,
      playerRadius * 2
    );
    context.restore();

    if (player != me && username) {
      const showScore = score >= 100;
      const yNamePos = canvasY - playerRadius - (showScore ? 20 : 8);
      context.fillStyle = "white";
      context.textAlign = "center";
      context.fillText(username, canvasX, yNamePos); // username
      if (showScore)
        context.fillText(
          // score
          FormatLargeNumber(score, 2),
          canvasX,
          canvasY - playerRadius - 8
        );
    } else if (player === me) {
      this.score = score;
    }

    // Draw health bar
    context.fillStyle = "white";
    context.fillRect(canvasX - playerRadius, canvasY + playerRadius + 8, playerRadius * 2, 2);
    context.fillStyle = "red";
    context.fillRect(
      canvasX - playerRadius + (playerRadius * 2 * hp) / max_hp,
      canvasY + playerRadius + 8,
      playerRadius * 2 * (1 - hp / max_hp),
      2
    );
  };

  renderBullet = (me, bullet) => {
    const now = new Date().valueOf();

    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");
    const { x, y, damage, blowupSize } = bullet;

    context.save();
    if (blowupSize) context.globalAlpha = (1 - blowupSize / 10) / 2;

    context.drawImage(
      getAsset("bullet.svg"),
      canvas.width / 2 + x - me.x - damage,
      canvas.height / 2 + y - me.y - damage,
      damage * 2 + blowupSize,
      damage * 2 + blowupSize
    );
    context.restore();
  };

  renderOrb = (me, orb) => {
    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");
    const { x, y } = orb;

    const canvasX = canvas.width / 2 + x - me.x;
    const canvasY = canvas.height / 2 + y - me.y;

    context.drawImage(
      getAsset("orb.svg"),
      canvasX - SMALL_ORB_HP,
      canvasY - SMALL_ORB_HP,
      SMALL_ORB_HP * 2,
      SMALL_ORB_HP * 2
    );

    if (orb.hp !== SMALL_ORB_HP) {
      // Draw health bar
      context.fillStyle = "white";
      context.fillRect(canvasX - SMALL_ORB_HP, canvasY + SMALL_ORB_HP + 8, SMALL_ORB_HP * 2, 2);
      context.fillStyle = "red";
      context.fillRect(
        canvasX - SMALL_ORB_HP + (SMALL_ORB_HP * 2 * orb.hp) / SMALL_ORB_HP,
        canvasY + SMALL_ORB_HP + 8,
        SMALL_ORB_HP * 2 * (1 - orb.hp / SMALL_ORB_HP),
        2
      );
    }
  };

  renderBot = (me, bot) => {
    const canvas = this.refs.canvas;
    const context = canvas.getContext("2d");
    const { x, y, rotation, hp, max_hp } = bot;

    const canvasX = canvas.width / 2 + x - me.x;
    const canvasY = canvas.height / 2 + y - me.y;

    const botRadius = Math.min(max_hp / 20 + 20, PLAYER_MAX_SIZE);

    context.save();
    context.translate(canvasX, canvasY);
    if (rotation && rotation > 0.01 && rotation < 6.27) {
      context.rotate(rotation);
    } else {
      context.rotate(0);
    }
    context.drawImage(getAsset("bot.svg"), -botRadius, -botRadius, botRadius * 2, botRadius * 2);
    context.restore();

    if (hp !== max_hp) {
      // Draw health bar
      context.fillStyle = "white";
      context.fillRect(canvasX - botRadius, canvasY + botRadius + 8, botRadius * 2, 2);
      context.fillStyle = "red";
      context.fillRect(
        canvasX - botRadius + (botRadius * 2 * hp) / max_hp,
        canvasY + botRadius + 8,
        botRadius * 2 * (1 - hp / max_hp),
        2
      );
    }
  };

  render() {
    return (
      <div>
        <canvas id="game-canvas" ref="canvas"></canvas>
        <div id="disconnect-modal" className="hidden">
          <div>
            <h2>Disconnected from Server </h2>
            <hr />
            <button id="reconnect-button">RECONNECT</button>
          </div>
        </div>
      </div>
    );
  }
}
export default Canvas;
