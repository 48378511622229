import io from "socket.io-client";
import { throttle } from "throttle-debounce";
// import { processGameUpdate } from './state';

const constants = require("../shared/constants");

// const socketProtocol = window.location.protocol === "https" ? "wss" : "ws";
// const socketProtocol = window.location.protocol === "https:" ? "https" : "ws";
// console.log(window.location.protocol);
const socket = io(`${window.location.protocol}//${window.location.host}`, {
  // const socket = io(`${socketProtocol}://${window.location.host}`, {
  // reconnection: true,
  // transports: ["websocket"],
});
const connectedPromise = new Promise((resolve) => {
  socket.on("connect", () => {
    console.log("Connected to server!");
    resolve(socket);
  });
});

export const connectToServer = (onGameOver) =>
  connectedPromise.then((socket) => {
    // Register callbacks
    // socket.on(constants.MSG_TYPES.GAME_UPDATE, processGameUpdate);
    // socket.on(constants.MSG_TYPES.GAME_OVER, onGameOver);

    socket.on("disconnect", () => {
      console.log("Disconnected from server.");
      // document.getElementById("disconnect-modal").classList.remove("hidden");
      // document.getElementById("reconnect-button").onclick = () => {
      //   window.location.reload();
      // };
    });
    return socket;
  });

// export const addUser = userId => {
//   socket.emit(constants.MSG_TYPES.ADD_USER, userId);
// };

////// Transactions
export const addInvoice = throttle(100, (value) => {
  socket.emit(constants.MSG_TYPES.ADD_INVOICE, { value });
});

export const addWithdrawalRequest = throttle(100, (userId) => {
  socket.emit(constants.MSG_TYPES.ADD_WITHDRAWAL_REQUEST, userId);
});

export const createPlayer = throttle(100, ({ onSuccess, userId }) => {
  socket.emit(constants.MSG_TYPES.CREATE_PLAYER, { onSuccess, userId });
});

// export const createUser = throttle(20, ({ isAuthenticated, userId }) => {
//   socket.emit(constants.MSG_TYPES.CREATE_USER, { isAuthenticated, userId });
// });

export const getTransactions = (userId) => {
  socket.emit(constants.MSG_TYPES.GET_TRANSACTIONS, userId);
};

export const saveTransaction = throttle(100, ({ invoiceId, onSuccess, userId }) => {
  socket.emit(constants.MSG_TYPES.INVOICE_AUTH_CONFIRMED, { invoiceId, onSuccess, userId });
});

////// Game
export const play = (username, publicKey) => {
  socket.emit(constants.MSG_TYPES.JOIN_GAME, { username, publicKey });
};

export const updateRotation = throttle(16.66 * 2, (rot) => {
  socket.emit("Rotation", rot);
});

export const updateMovement = throttle(80, (mov) => {
  socket.emit("Movement", mov);
});

export const stopMovement = throttle(50, () => {
  socket.emit("StopMovement");
});

export const shoot = throttle(90, () => {
  socket.emit(constants.MSG_TYPES.SHOOT);
});

export const stopShoot = throttle(20, () => {
  socket.emit(constants.MSG_TYPES.STOPSHOOT);
});

export const upgradeSkill = throttle(100, (skill) => {
  socket.emit("UpgradeSkill", skill);
});

export const endGame = ({ isAuthenticated, userId }) => {
  socket.emit(constants.MSG_TYPES.END_GAME, { isAuthenticated, userId });
};
