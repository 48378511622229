import {
  updateRotation,
  updateMovement,
  stopMovement,
  shoot,
  stopShoot,
  upgradeSkill,
} from "./networking";

let leftPressed = false;
let rightPressed = false;
let upPressed = false;
let downPressed = false;
let movementInterval = null;

function onMouseInput(e) {
  handleRotation(e.clientX, e.clientY);
}

function onMouseDown(e) {
  shoot();
}

function onMouseUp(e) {
  stopShoot();
}

function onTouchInput(e) {
  const touch = e.touches[0];
  handleRotation(touch.clientX, touch.clientY);
}

function onKeyDown(e) {
  if (e.keyCode === 32) shoot(); // Spacebar

  // Movement directions
  if (e.keyCode === 65) {
    // a;
    leftPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 37) {
    // left
    leftPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 68) {
    // d
    rightPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 39) {
    // right
    rightPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 38) {
    // up
    upPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 87) {
    // w
    upPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 40) {
    // down
    downPressed = true;
    // handleMovement();
  }
  if (e.keyCode === 83) {
    // s
    downPressed = true;
    // handleMovement();
  }
}

function onKeyUp(e) {
  if (e.keyCode === 32) {
    // Spacebar
    return stopShoot();
  }

  // Movement directions
  if (e.keyCode === 65) leftPressed = false; // a
  if (e.keyCode === 37) leftPressed = false; // left
  if (e.keyCode === 68) rightPressed = false; // d
  if (e.keyCode === 39) rightPressed = false; // right
  if (e.keyCode === 38) upPressed = false; // up
  if (e.keyCode === 87) upPressed = false; // w
  if (e.keyCode === 40) downPressed = false; // down
  if (e.keyCode === 83) downPressed = false; // s
  if (e.keyCode === 49) upgradeSkill("bulletDamage");
  if (e.keyCode === 50) upgradeSkill("bulletSpeed");
  if (e.keyCode === 51) upgradeSkill("speed");
  if (e.keyCode === 52) upgradeSkill("fireRate");

  // if (leftPressed || rightPressed || upPressed || downPressed) handleMovement();
}

function handleRotation(x, y) {
  const rotation = Math.atan2(x - window.innerWidth / 2, window.innerHeight / 2 - y);

  updateRotation(rotation);
}

function handleMovement() {
  if (!leftPressed && !rightPressed && !upPressed && !downPressed) return stopMovement();

  let playerX = window.innerWidth / 2;
  if (rightPressed) {
    playerX = playerX + 5;
  } else if (leftPressed) {
    playerX = playerX - 5;
  } else {
    playerX = playerX;
  }

  let playerY = window.innerHeight / 2;

  if (downPressed) {
    playerY = playerY + 5;
  } else if (upPressed) {
    playerY = playerY - 5;
  } else {
    playerY = playerY;
  }

  const direction = Math.atan2(playerX - window.innerWidth / 2, window.innerHeight / 2 - playerY);

  updateMovement(direction);
}

export function startCapturingInput() {
  window.addEventListener("mousemove", onMouseInput);
  window.addEventListener("mousedown", onMouseDown);
  window.addEventListener("mouseup", onMouseUp);

  window.addEventListener("keydown", onKeyDown);
  window.addEventListener("keyup", onKeyUp);

  window.addEventListener("touchstart", onTouchInput);
  window.addEventListener("touchmove", onTouchInput);
  movementInterval = setInterval(handleMovement, 1000 / 60);
}

export function stopCapturingInput() {
  window.removeEventListener("mousemove", onMouseInput);
  window.removeEventListener("mousedown", onMouseDown);
  window.removeEventListener("mouseup", onMouseUp);

  window.removeEventListener("keydown", onKeyDown);
  window.removeEventListener("keyup", onKeyUp);

  window.removeEventListener("touchstart", onTouchInput);
  window.removeEventListener("touchmove", onTouchInput);
  clearInterval(movementInterval);
}
