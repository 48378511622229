import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { authActions } from "redux/modules/auth";
import { userActions } from "redux/modules/user";
import { connectToServer } from "utils/networking";
import { downloadAssets } from "utils/assets";
import * as authService from "utils/auth";
import SocketContext from "utils/socket";
import constants from "shared/constants";
import WelcomePage from "containers/WelcomePage";
import GamePage from "containers/GamePage";
import NotFoundPage from "containers/NotFoundPage";
import Loading from "components/Loading";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = null;
    this.state = { connected: null };
  }

  componentDidMount() {
    this.initialLoad();
  }

  initialLoad = async () => {
    await this.setSocketMonitors();
    this.handleAuth();
  };

  handleAuth = () => {
    // Add callback for lock's `authenticated` event
    const { history, loginError, loginSuccess, getUser } = this.props;
    authService.lock.on("authenticated", (authResult) => {
      authService.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          return loginError(error);
        }
        authService.setToken(authResult.idToken); // static method
        authService.setProfile(profile); // static method
        loginSuccess(profile);
        getUser(profile, this.socket);
        history.push({ pathname: "/" });
        authService.lock.hide();
      });
    });
    // Add callback for lock's `authorization_error` event
    authService.lock.on("authorization_error", (error) => {
      loginError(error);
      history.push({ pathname: "/" });
    });
  };

  setSocketMonitors = async () => {
    const connected = await connectToServer();
    await downloadAssets();
    this.setState({ connected });
    this.socket = connected;
  };

  render() {
    if (!this.state.connected) return <Loading />;
    return (
      <SocketContext.Provider value={this.state.connected}>
        <div>
          <Switch>
            <Route exact={true} path="/" component={WelcomePage} />
            <Route path="/game" component={GamePage} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </SocketContext.Provider>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loginError: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (profile) => dispatch(authActions.loginSuccess(profile)),
  loginError: (error) => dispatch(authActions.loginError(error)),
  getUser: (profile, socket) => dispatch(userActions.getUser(profile, socket)),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
