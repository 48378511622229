import React from "react";
import { Col, Row } from "reactstrap";

const SkillUpgrader = ({ skills, availableSkillPoints, upgradeSkill }) => {
  return (
    <div id="skill-upgrader">
      <Row className="mb-sm">
        <Col xs="12">
          <h5>{Math.floor(availableSkillPoints)}x point to spend</h5>
        </Col>
      </Row>
      <Row className="mb-sm">
        <Col xs="2">
          <span>Key</span>
        </Col>
        <Col xs="6" style={{ textAlign: "center" }}>
          <span>Skill</span>
        </Col>
      </Row>
      <Row className="mb-sm">
        <Col xs="1">1</Col>
        <Col xs="10">Bullet Damage (lvl. {Math.floor(skills.bulletDamageLevel)})</Col>
        {skills.bulletDamageLevel < 10 && (
          <Col xs="1">
            <button onClick={() => upgradeSkill("bulletDamage")}>+</button>
          </Col>
        )}
      </Row>
      <Row className="mb-sm">
        <Col xs="1">2</Col>
        <Col xs="10">Bullet Speed (lvl. {Math.floor(skills.bulletSpeedLevel)})</Col>
        {skills.bulletSpeedLevel < 10 && (
          <Col xs="1">
            <button onClick={() => upgradeSkill("bulletSpeed")}>+</button>
          </Col>
        )}
      </Row>
      <Row className="mb-sm">
        <Col xs="1">3</Col>
        <Col xs="10">Movement Speed (lvl. {Math.floor(skills.speedLevel)})</Col>
        {skills.speedLevel < 10 && (
          <Col xs="1">
            <button onClick={() => upgradeSkill("speed")}>+</button>
          </Col>
        )}
      </Row>
      <Row className="mb-sm">
        <Col xs="1">4</Col>
        <Col xs="10">Fire rate (lvl. {Math.floor(skills.fireRateLevel)})</Col>
        {skills.fireRateLevel < 10 && (
          <Col xs="1">
            <button onClick={() => upgradeSkill("fireRate")}>+</button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SkillUpgrader;
