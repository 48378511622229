import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  addInvoice,
  addWithdrawalRequest,
  createPlayer,
  endGame,
  play,
  saveTransaction,
  upgradeSkill,
} from "utils/networking";
// import { interpolateObject, interpolateObjectArray } from "utils/tools";
import { startCapturingInput, stopCapturingInput } from "utils/input";
import SocketContext from "utils/socket";
import getErrorMsg from "utils/getErrorMsg";
import constants from "shared/constants";
import { Alert, Button } from "reactstrap";
import Leaderboard from "components/Leaderboard";
import ScoreAndLevel from "components/ScoreAndLevel";
import SkillUpgrader from "components/SkillUpgrader";
import Canvas from "components/Canvas";
import GameOver from "components/GameOver";
import QRModal from "components/QRModal";

class Game extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
      errorMessage: "",
      firstServerTimestamp: 0,
      gameStart: 0,
      invoice: null,
      isModalOpen: false,
      leaderboard: [],
      loading: false,
      playing: false,
      playerId: null, // String
      redirectToWelcome: false,
      satoshisEarned: 0,
      skillStats: {
        availableSkillPoints: 0,
        fireRateLevel: 0,
        bulletSpeedLevel: 0,
        speedLevel: 0,
        bulletDamageLevel: 0,
      },
    };
  }

  componentDidMount() {
    this.setSocketMonitors();
    this.startGame();
  }

  setSocketMonitors = async () => {
    const socket = this.props.socket;
    socket.on(constants.MSG_TYPES.ADDED_INVOICE, this.onInvoiceAdded);
    socket.on(constants.MSG_TYPES.ADDED_WITHDRAWAL_REQUEST, this.onWithdrawalRequestAdded);
    socket.on(constants.MSG_TYPES.GAME_OVER, this.onGameOver);

    socket.on(constants.MSG_TYPES.UPDATE_BALANCE, this.onUpdateBalance);
    socket.on(constants.MSG_TYPES.ON_ERROR, this.onError);
    socket.on(constants.MSG_TYPES.ON_SUCCESS, this.onSuccess);

    socket.on(constants.MSG_TYPES.INVOICE_PAID, this.onInvoicePaid);
    socket.on(constants.MSG_TYPES.WITHDRAWAL_REQUEST_PAID, this.onWithdrawalRequestPaid);
    socket.on("Skill Point Stats", this.updateSkillStats);
    socket.on("Sends Player ID", this.savePlayerId);
    socket.on("Leaderboard Update", this.updateLeaderboard);
    socket.on("Player Score", this.updateSatoshisEarned);
  };

  initState = () => {
    this.setState({ gameStart: 0, firstServerTimestamp: 0 });
  };

  onUpdateBalance = (balance) => {
    console.log("onUpdateBalance", balance);
    this.setState({ balance });
  };

  startGame = () => {
    const { auth, user } = this.props;
    if (!user.user_id) return this.setState({ redirectToWelcome: true });
    play(auth.profile.name, auth.profile.publicKey, user.user_id);

    this.startPlaying();
    this.initState();
    startCapturingInput();
  };

  startPlaying = () => {
    this.setState({
      playing: true,
      satoshisEarned: 0,
      skillStats: {
        availableSkillPoints: 0,
        fireRateLevel: 0,
        bulletSpeedLevel: 0,
        speedLevel: 0,
        bulletDamageLevel: 0,
      },
      isModalOpen: false,
    });
  };

  onGameOver = () => {
    stopCapturingInput();
    const { satoshisEarned } = this.state;
    const { auth, user } = this.props;
    console.log("onGameOver satoshisEarned", satoshisEarned);
    endGame({ isAuthenticated: auth.isAuthenticated, userId: user.user_id });
    this.setState({ playing: false });
  };

  playAgain = async () => {
    const balance = this.state.balance;
    console.log("playAgain balance", balance);
    const canPlay = balance >= constants.PLAYER_COST;
    if (canPlay) {
      this.handleCreatePlayer();
      this.setState({ balance: balance - constants.PLAYER_COST });
    } else {
      this.setState({ loading: true });
      addInvoice(constants.PLAYER_COST);
    }
  };

  onInvoiceAdded = (invoice) => {
    console.log("onInvoiceAdded", invoice);
    this.setState({ invoice, isModalOpen: true, loading: false });
  };

  onInvoicePaid = async ({ amount, internalId, invoiceId }) => {
    console.log("onInvoicePaid", amount, internalId, invoiceId);
    const { socket, user } = this.props;
    if (internalId !== socket.id) return;
    // Single Game purchase redeems and starts game immediately
    await saveTransaction({ invoiceId, onSuccess: "createPlayer", userId: user.user_id });
  };

  handleCreatePlayer = async () => {
    await createPlayer({ onSuccess: "startGame", userId: this.props.user.user_id });
  };

  cashOut = () => {
    console.log("cashOut balance", this.state.balance);
    return addWithdrawalRequest(this.props.user.user_id);
  };

  onWithdrawalRequestAdded = (invoice) => {
    console.log("onWithdrawalRequestAdded", invoice);
    this.setState({ invoice, isModalOpen: true, loading: true });
  };

  onWithdrawalRequestPaid = ({ amount, internalId, invoiceId }) => {
    console.log("onWithdrawalRequestPaid", amount, internalId, invoiceId);
    if (internalId !== this.props.socket.id) return;
    saveTransaction({ invoiceId, userId: this.props.user.user_id });
    this.setState({ redirectToWelcome: true });
  };

  handleUpgradeSkill = (skill) => {
    upgradeSkill(skill);
  };

  toggleModal = () => {
    const isModalOpen = this.state.isModalOpen ? false : true;
    this.setState({ isModalOpen, loading: false });
  };

  updateSkillStats = (update) => {
    const {
      availableSkillPoints,
      fireRateLevel,
      bulletSpeedLevel,
      bulletDamageLevel,
      speedLevel,
    } = update;

    const skillStats = {
      availableSkillPoints,
      fireRateLevel,
      bulletSpeedLevel,
      speedLevel,
      bulletDamageLevel,
    };
    this.setState({ skillStats });
  };

  savePlayerId = (playerId) => {
    console.log("Saves player ID", playerId);
    this.setState({ playerId });
  };

  onError = (error) => {
    const errorMessage = getErrorMsg(error);
    this.setState({ errorMessage });
  };

  onSuccess = (callback) => {
    console.log("callback", callback);
    this.setState({ errorMessage: "" });
    switch (callback) {
      case "createPlayer":
        return this.handleCreatePlayer();
      case "startGame":
        return this.startGame();
      default:
        return null;
    }
  };

  updateLeaderboard = (leaderboard) => {
    this.setState({ leaderboard });
  };

  updateSatoshisEarned = (satoshisEarned) => {
    this.setState({ satoshisEarned });
  };

  render() {
    const {
      balance,
      errorMessage,
      firstServerTimestamp,
      gameStart,
      invoice,
      isModalOpen,
      leaderboard,
      loading,
      playing,
      playerId,
      redirectToWelcome,
      satoshisEarned,
      skillStats,
    } = this.state;
    const { socket } = this.props;
    const username = this.props.auth.profile.nickname || this.props.auth.profile.name;

    if (redirectToWelcome) {
      return <Redirect push to="/" />;
    }

    console.log(skillStats);

    return (
      <>
        <div id="sidebar">
          {playing ? (
            <>
              <Leaderboard leaderboard={leaderboard} />
              <ScoreAndLevel username={username} score={satoshisEarned} />
              <Button color="secondary" size="sm" className="full-width" onClick={this.onGameOver}>
                End Game
              </Button>
              {skillStats && skillStats.availableSkillPoints > 0 && (
                <SkillUpgrader
                  skills={skillStats}
                  upgradeSkill={this.handleUpgradeSkill}
                  availableSkillPoints={skillStats.availableSkillPoints}
                />
              )}
            </>
          ) : (
            <GameOver
              cashOut={this.cashOut}
              balance={balance}
              loading={loading}
              playAgain={this.playAgain}
              satoshisEarned={satoshisEarned}
              username={username}
            />
          )}
        </div>
        <Canvas
          socket={socket}
          gameStart={gameStart}
          firstServerTimestamp={firstServerTimestamp}
          playerId={playerId}
        />
        {errorMessage && (
          <Alert color="danger" className="bottom-alert">
            {errorMessage}
          </Alert>
        )}
        {invoice && isModalOpen && <QRModal invoice={invoice} toggleModal={this.toggleModal} />}
      </>
    );
  }
}

Game.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    error: PropTypes.object,
  }).isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user.info,
  };
}

const GameWithSocket = (props) => (
  <SocketContext.Consumer>{(socket) => <Game {...props} socket={socket} />}</SocketContext.Consumer>
);

export default connect(mapStateToProps)(GameWithSocket);
