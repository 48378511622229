import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "reactstrap";

const Leaderboard = ({ leaderboard }) => {
  return (
    <Container className="game-menu">
      <Row>
        <Col xs="12">
          <h5>Leaderboard</h5>
        </Col>
      </Row>
      {leaderboard &&
        leaderboard.map &&
        leaderboard.map(item => (
          <Row key={item.id} className="mb-sm">
            <Col xs="10">{item.username}</Col>
            <Col xs="2">{item.score}</Col>
          </Row>
        ))}
    </Container>
  );
};

Leaderboard.propTypes = {
  leaderboard: PropTypes.array.isRequired,
  handleEndGame: PropTypes.func
};

export default Leaderboard;
