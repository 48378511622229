import * as types from './types'
import * as authService from 'utils/auth'

const authReducer = (
  state = {
    isAuthenticated: !authService.isTokenExpired(),
    isFetching: false,
    profile: authService.getProfile(),
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        profile: action.payload,
      }
    case types.GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        profile: action.payload,
      }
    case types.LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        profile: {},
        error: action.error,
      }
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        profile: {},
      }
    default:
      return state
  }
}

export default authReducer
