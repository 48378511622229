export function getLevelAndProgression(score) {
	// Needs to sync with level math on backend in /player file.
	const levelConst = 0.5; // Controls rate of leveling up based on score.
	const sqrtScore = Math.sqrt(score); // Makes level progression exponential.
	const rawLevel = levelConst * sqrtScore;
	const flooredLevel = Math.floor(rawLevel);
	const max40 = Math.min(flooredLevel, 40); // Max lvl 40.
	const level = Math.max(max40, 1); // Can't be 0
	const levelProgression = rawLevel % 1;
	return { level, levelProgression };
}

export function currentServerTime(firstServerTimestamp, gameStart, renderDelay) {
	return firstServerTimestamp + (Date.now() - gameStart) - renderDelay;
}

// Returns the index of the base update, the first game update before
// current server time, or -1 if N/A.
export function getBaseUpdate(currentServerTime, gameUpdates) {
	for (let i = gameUpdates.length - 1; i >= 0; i--) {
		if (gameUpdates[i].t <= currentServerTime) {
			return i;
		}
	}
	return -1;
}

export function distanceTo(object1, object2) {
	if (!object1 || !object2) return 0;
	const dx = object1.x - object2.x;
	const dy = object1.y - object2.y;
	return Math.sqrt(dx * dx + dy * dy);
}
